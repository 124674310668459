$border-radius: 1.3rem;
.wrapper {
  position: relative;

  &::before {
    position: absolute;
    right: 0;
    left: 0;
    width: 95%;
    height: 100%;
    margin: -1rem auto 0;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: $border-radius;
    transform: translateY(25px);
    content: '';
  }

  .wrapper-box {
    background-color: #645395;
    border-radius: $border-radius;
    transform: translateY(25px);

    &::before {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      display: block;
      background-color: rgba(0, 0, 0, 0.15);
      border-radius: $border-radius;
      content: '';
    }

    .content {
      flex: 1 1 0%;
      padding: 15px;
      background-color: #e3ecf4;
      border-radius: $border-radius;
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

      &::before {
        display: block;
        width: 100%;
        height: 1px;
        content: '';
      }
    }
  }
}
