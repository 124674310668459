/* stylelint-disable selector-class-pattern -- CamelCase class names */

:global .header-search {
  display: inline-block;
  margin-right: 1em;
}

:global .header-search .ais-SearchBox {
  display: flex;
  flex-direction: row-reverse;
}

:global .ais-SearchBox-form {
  display: block;
  width: fit-content;
  font-size: var(--bs-body-font-size);
  line-height: var(--bs-body-line-height);
  background-color: inherit;
}

:global .ais-SearchBox-input {
  width: 16rem;
  height: 2.5rem;
  color: var(--bs-body-color);
  background-color: var(--bs-search);
  border: 0;
  border-radius: 4rem;
  outline: none;
  box-shadow: none;
}

:global .header-search .ais-SearchBox-input {
  transition: width 0.3s;

  &:focus {
    @media (min-width: 768px) {
      width: 22rem;
      /* expands on big screen devices */
      max-width: 22rem;
      background-color: #fff;
    }
  }

  &:not(:placeholder-shown) {
    @media (min-width: 768px) {
      width: 22rem;
    }
  }
}

:global .ais-RangeInput-form {
  flex-wrap: wrap;
  justify-content: center;
}
