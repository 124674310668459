.app-navbar {
  font-size: 1.1em;
}

.app-navbar span {
  vertical-align: sub;
}

:global .app-navbar .navbar-brand {
  display: inline-flex;
  flex-flow: column;
}

.nav-settings-dropdown .dropdown-toggle.nav-link::after {
  display: none;
}

.navbar-expand-md .navbar-nav .nav-link {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.navbar-nav .nav-link:hover {
  color: #fff;
}
.navbar-nav .nav-link.active {
  background-color: #645395;
  border-radius: 30px;
  box-shadow: inset 0px 0px 5px #4a377e;
  border: 1px solid #ffffff29;
}

.dropdown-menu a:hover {
  background-color: #e3ecf4;
}
