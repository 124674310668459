@font-face {
  font-family: Neo Sans Std;
  src: url('./fonts/NeoSansStd-Regular.otf');
}

@import './bootstrap-variables';

@import '~bootstrap/scss/bootstrap';

//Report Dashboard UI CSS
$border-radius: 1.3rem;

// mixin for button styles
@mixin button-styles {
  border-radius: $border-radius;
  padding: 0.5rem 1rem;
}

// overriding bootstrap default styles
@media (min-width: 769px) {
  .container {
    max-width: 99%;
  }
  .me-auto {
    margin-left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-nav .nav-link:hover {
    color: #fff;
  }
  .navbar-nav .nav-link.active {
    background-color: #645395;
    border-radius: 30px;
    box-shadow: inset 0px 0px 5px #4a377e;
    border: 1px solid #ffffff29;
  }

  .dropdown-menu a:hover {
    background-color: #e3ecf4;
  }
}

.btn {
  @include button-styles;
}

.btn-link {
  text-decoration: none;
  color: #333;

  &:hover {
    color: #d9534f;
  }
}

.switch-on.btn-sm, .switch-off.btn-sm {
  padding-left: 0.5rem;
  padding-top: 0.3rem;
}
