@media print {
  .container {
    max-width: none !important;
  }
}

:global .btn-return {
  background: #8877b6 !important;
  color: #fff !important;
  border: 1px solid #ffffff1a !important;
  border-radius: 1.3rem !important;
  padding: 0.5rem 1rem !important;
}

:global .btn-return:hover {
  background: #4a377e !important;
}

:global .btn {
  border-radius: 1.3rem !important;
  padding: 0.5rem 1rem !important;
}

:global .btn-link {
  text-decoration: none !important;
  color: #333 !important;
}
:global .btn-link:hover {
  color: #d9534f !important;
}

:global .switch-on.btn-sm,
:global .switch-off.btn-sm {
  padding-left: 0.5rem !important;
  padding-top: 0.3rem !important;
}
